import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO
      title="QA Engineer - Careers at D2i Technology"
      description="Join our team as a QA Engineer. 1-2 years of experience in ensuring software quality through thorough testing and defect analysis."
    />
    <section id="career-detail" className="career-detail">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>QA Engineer</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  QA Engineer
                </li>
              </ol>
            </nav>
            <div className="job-card">
              <div className="job-header">
                <h2>Job Description</h2>
                <Link
                  to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
              <p>
                We are seeking a QA Engineer with 1-2 years of experience in ensuring software quality through thorough testing and defect analysis.
              </p>

              <h3>Requirements:</h3>
              <ul>
                <li>1-2 years of experience in software testing</li>
                <li>Familiarity with testing methodologies and tools</li>
                <li>Strong analytical and problem-solving skills</li>
                <li>Attention to detail and a commitment to quality</li>
                <li>Ability to work collaboratively in a team environment</li>
                <li>Experience in writing and executing test cases</li>
              </ul>

              <h3>Responsibilities:</h3>
              <ul>
                <li>Conduct testing to ensure software quality and performance</li>
                <li>Identify, document, and track defects</li>
                <li>Collaborate with developers to resolve issues</li>
                <li>Participate in the design and implementation of test plans</li>
                <li>Perform regression testing as needed</li>
              </ul>

              <h3>What We Offer:</h3>
              <ul>
                <li>Competitive salary and benefits</li>
                <li>Opportunities for career advancement and professional development</li>
                <li>Collaborative and innovative work environment</li>
                <li>Work-life balance with flexible work arrangements</li>
              </ul>
            </div>
            <div className="apply-footer">
              <p>Ready to ensure our software quality?</p>
              <Link
                to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                target="_blank"
                className="btn btn-primary"
              >
                Apply for this Position
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)